import React from "react"
import {Link} from 'gatsby'
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import LinkContainer from '../components/LinkContainer'

import styled from "@emotion/styled"

const PageHeader = styled.h1`
  font-family: "komika_title-axis_regular", sans-serif;
  font-weight: 400;
  font-style: normal;
`

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <PageHeader>NOT FOUND</PageHeader>
    <p>Holey rusted metal, Batman...You just hit a route that doesn&rsquo;t exist.</p>
    <LinkContainer>
      <Link to="/">Go back to the homepage</Link>
    </LinkContainer>
  </Layout>
)

export default NotFoundPage
